// extracted by mini-css-extract-plugin
export var ArtistDescription = "Blade-module--ArtistDescription--nBYIZ";
export var ArtistInfos = "Blade-module--ArtistInfos--ese2Q";
export var ButtonWrapper = "Blade-module--ButtonWrapper --tNdPE";
export var CardWrapper = "Blade-module--CardWrapper--1D-pv";
export var CarrouselWrapper2 = "Blade-module--CarrouselWrapper2--KCV5V";
export var Citations = "Blade-module--Citations--CtlNb";
export var DescriptionWrapper = "Blade-module--DescriptionWrapper--sCRjp";
export var ImageWrapper = "Blade-module--ImageWrapper--rhZpp";
export var LinkWrapper = "Blade-module--LinkWrapper--P20ZF";
export var MobileProtrait = "Blade-module--MobileProtrait---VWrZ";
export var More = "Blade-module--More--pBc7X";
export var MoreButton = "Blade-module--MoreButton--qABSH";
export var NameWrapper = "Blade-module--NameWrapper--T8-Tb";
export var PdpWrapper = "Blade-module--PdpWrapper--ONu9s";
export var PhotosWrapper = "Blade-module--PhotosWrapper--v6vcV";
export var ProfilWrapper = "Blade-module--ProfilWrapper--+ZWF0";
export var TitleWrapper = "Blade-module--TitleWrapper--twGM5";
export var Wrapper = "Blade-module--Wrapper--oJNCF";